// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth, googleAuthProvider } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyCkV3t8D9id4AH9p-GLfLHys-cPUDtzhnA",
  authDomain: "jayas-1db92.firebaseapp.com",
  databaseURL: "https://jayas-1db92-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "jayas-1db92",
  storageBucket: "jayas-1db92.appspot.com",
  messagingSenderId: "695257723268",
  appId: "1:695257723268:web:3cf02d01db5dca110bedd1",
  measurementId: "G-KZNBP5PPCQ"
};
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore();
const storage = getStorage(firebaseApp);
const auth = getAuth();
const provider = new GoogleAuthProvider(); // add the social login of google (that little popuptells you to pick up which email you wanna log in with)

export {auth, provider, storage};
export default db;


